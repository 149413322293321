<template>
  <div class="container">
    <h4 class="text-center my-4">Список заявок на вакансии</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <DataTable :value="vacancyApplications" showGridlines stripedRows
                 :globalFilterFields="['lastname','firstname']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск кандидата"/>
            </span>
          </div>
        </template>
        <template #empty>
          Кандидат не найден
        </template>
        <template #loading>
          Идет поиск кандидатов. Пожалуйста подождите.
        </template>

        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{index+1}}
          </template>
        </Column>

        <Column header="ФИО">
          <template #body="{data}">
            <a @click.prevent="goToUser(data.id)" class="text-decoration-none" href="#">
              {{data.lastname}} {{data.firstname}}
            </a>
          </template>
        </Column>

        <Column header="Дисциплины">
          <template #body="{data}">

            <div v-for="(application, index) in data.applications" :key="index" class="my-4">
              <div class="fw-bold">
                {{application.education_discipline_name}}
              </div>
              <div>
                <i v-if="application.status===statuses.confirm.id" class="pi pi-check text-success me-1 fw-bold"></i>
                <i v-else-if="application.status===statuses.reject.id"
                   class="pi pi-times text-danger me-1 fw-bold"></i>
                <i v-else-if="application.status===statuses.fast.id" class="pi pi-bolt text-primary me-1 fw-bold"></i>
                <span class="me-2">{{application.education_discipline_code}}</span>
                <span class="me-2">(Кредит {{application.education_discipline_credit}})</span>
                {{application.study_level_name}} - {{application.language}}
              </div>
            </div>
          </template>
        </Column>
        <Column header="Контракт" class="text-center" :style="{width: '15%'}">
          <template #body="{data}">
            <div v-if="data.contract" class="text-start border rounded-3 px-2 py-1">
              <div class="mb-2">
                <div class="fw-bold">Тип</div>
                <div>{{contractTypes.find(i=>i.id==data.contract.contract_type)?.name}}</div>
              </div>
              <div class="mb-2">
                <div class="fw-bold">Срок</div>
                <div>{{contractTerms.find(i=>i.term==data.contract.contract_term)?.name}}</div>
              </div>
              <div class="mb-2">
                <div class="fw-bold">Дата трудового договора</div>
                <div>{{convertTimestampToDate(data.contract.work_start_date)}}</div>
              </div>
              <div class="mb-2">
                <div class="fw-bold">С даты</div>
                <div>{{convertTimestampToDate(data.contract.contract_start_date)}}</div>
              </div>
              <div class="mb-2">
                <div class="fw-bold">До даты</div>
                <div>{{convertTimestampToDate(data.contract.contract_end_date)}}</div>
              </div>
              <div class="mb-2">
                <div class="fw-bold">Должность</div>
                <div>{{teachingPositions.find(i=>i.id==data.contract.teaching_position_id)?.name}}</div>
              </div>
            </div>
            <div v-else>
              <Button icon="pi pi-plus" label="Контракт" class="p-button-rounded"
                      @click="openAddContractDialog(data.id)"/>
            </div>
          </template>
        </Column>

        <Column header="Действия" class="text-center" :style="{width: '15%'}">
          <template #body="{data}">
            <div class="row">
              <div v-if="data.contract" class="col-md-8">
                <Button v-if="data.is_profile_vote == false" icon="pi pi-check" class="p-button-rounded p-button-success m-1"
                        @click="openActionApplication(data.id, statuses.confirm)"/>
                <Button icon="pi pi-bolt" class="p-button-rounded m-1"
                        @click="openActionApplication(data.id, statuses.fast)"/>
              </div>
              <div v-else class="col-md-8">
                Для принятия нужно создать контракт
              </div>
              <div class="col-md-4">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger m-1"
                        @click="openActionApplication(data.id, statuses.reject)"/>
              </div>
            </div>

          </template>
        </Column>
      </DataTable>

    </div>


    <!-- add contract dialog -->
    <Dialog header="Добавление контракта" :visible="displayAddContract" :closable="false" :modal="true"
            :style="{width: '100%', maxWidth: '600px'}">
      <div class="my-3">
        <h5>{{contractUserName}}</h5>

        <div class="mt-4">
          <div v-if="contract.is_checked">
            Практик
          </div>
          <div v-else>
            Практик? <input type="checkbox" :disabled="disabledIsPractice"
                            @input="changeIsPractice(contract.user_id, $event)">
          </div>

        </div>

        <div class="form-group row mt-4">
          <label class="col-md-3 col-form-label">Выберите тип</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="contract.contract_type">
              <option v-for="(contractType, contractTypeIndex) in [{name: 'Выберите тип'}, ...contractTypes]"
                      :key="contractTypeIndex"
                      :value="contractType?.id">
                {{ contractType.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-4">
          <label class="col-md-3 col-form-label">Выберите срок</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="contract.contract_term">
              <option v-for="(contractTerm, contractTermIndex) in [{name: 'Выберите срок'}, ...contractTerms]"
                      :key="contractTermIndex"
                      :value="contractTerm?.term">
                {{ contractTerm.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row mt-4">
          <label for="work_start_date" class="col-md-3 col-form-label">
            Дата трудового договора
          </label>
          <div class="col-md-9">
            <input id="work_start_date" type="date" class="form-control" v-model="contract.work_start_date">
          </div>
        </div>

        <div class="form-group row mt-4">
          <label for="contract_start_date" class="col-md-3 col-form-label">
            С даты
          </label>
          <div class="col-md-9">
            <input id="contract_start_date" type="date" class="form-control" v-model="contract.contract_start_date">
          </div>
        </div>

        <div class="form-group row mt-4">
          <label for="contract_end_date" class="col-md-3 col-form-label">
            До даты
          </label>
          <div class="col-md-9">
            <input id="contract_end_date" type="date" class="form-control" v-model="contract.contract_end_date">
          </div>
        </div>

        <div class="form-group row mt-4">
          <label class="col-md-3 col-form-label">Должность</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="contract.teaching_position_id">
              <option v-for="(position, positionIndex) in teachingPositions" :key="positionIndex" :value="position.id">
                {{ position.name }}
              </option>
            </select>
          </div>
        </div>

      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeAddContractDialog" class="p-button-text"/>
        <Button label="Добавить" icon="pi pi-plus" @click="addContract" autofocus/>
      </template>
    </Dialog>
    <!-- end add contract dialog -->

    <!-- action dialog -->
    <Dialog :header="`${selectedUser.user?.lastname} ${selectedUser.user?.firstname}`"
            :visible="displayActionApplication" :closable="false" :modal="true"
            :style="{width: '100%', maxWidth: '600px'}">
      <div class="mb-3">
        <div class="mb-4">
          <div class="fw-bold">Выберите дисциплины:</div>
          <div v-for="(discipline, disciplineIndex) in selectedUser.user?.applications.filter(i=>!i.status)"
               :key="disciplineIndex"
               class="form-check my-3">
            <input class="form-check-input" type="checkbox" v-model="selectedUser.userSyllabuses"
                   :value="discipline.user_syllabus_id" :id="`discipline${disciplineIndex}`">
            <label class="form-check-label" :for="`discipline${disciplineIndex}`">
              <b>{{discipline.education_discipline_name}}</b>
              <br>
              {{discipline.education_discipline_code}}, Кредит {{discipline.education_discipline_credit}},
              {{discipline.study_level_name}} -
              {{discipline.language}}
            </label>
          </div>
        </div>

        <div>
          <div class="mb-2 fw-bold">Комментарий:</div>
          <textarea v-model="selectedUser.comment" class="form-control mb-2" rows="3"
                    placeholder="Комментарий"></textarea>
        </div>

      </div>
      <template #footer>
        <Button icon="pi pi-times" label="Закрыть" class="p-button-text" :disabled="loadingAction"
                @click="closeActionApplication"/>
        <Button
          icon="pi pi-check"
          :label="selectedUser.status.btnLabel"
          :class="selectedUser.status.btnClass"
          :disabled="!selectedUser.userSyllabuses.length"
          :loading="loadingAction"
          @click="actionApplication" autofocus/>

      </template>
    </Dialog>
    <!-- end action dialog -->


  </div>
</template>

<script>
  import {mapActions, mapMutations, mapState} from 'vuex'
  import {FilterMatchMode, FilterOperator} from 'primevue/api'
  import {convertDateInputToTimestamp, convertTimestampToDate} from "@/utils/helpers/date.helpers"

  export default {
    name: 'DepartmentHeadVacancyApplications',
    data() {
      return {
        loading: true,
        filters: {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
          'lastname': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
          'firstname': {
            operator: FilterOperator.AND,
            constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
          },
        },
        vacancyApplications: [],
        displayAddContract: false,
        contractTypes: [
          {
            id: 1,
            name: 'Штатный'
          },
          {
            id: 2,
            name: 'Совместитель'
          },
          {
            id: 3,
            name: 'Докторантура'
          },
          {
            id: 4,
            name: 'Гпх'
          },
          {
            id: 5,
            name: 'Внутренний совместитель'
          },
        ],
        contractTerms: [
          {
            term: 1,
            name: '1 год'
          },
          {
            term: 2,
            name: '2 года'
          },
          {
            term: 3,
            name: '3 года'
          },
          {
            term: 4,
            name: '1 семестр'
          }
        ],
        contract: {},
        contractUserName: '',
        selectedUser: {
          user: {},
          status: 0,
          comment: '',
          userSyllabuses: []
        },
        displayActionApplication: false,
        statuses: {
          confirm: {
            id: 1,
            btnClass: '',
            btnLabel: 'Принять',
            successMessage: 'Успешно принято'
          },
          reject: {
            id: 2,
            btnClass: 'p-button-danger',
            btnLabel: 'Отклонить',
            successMessage: 'Успешно отклонено'
          },
          fast: {
            id: 3,
            btnClass: '',
            btnLabel: 'Принять ускоренно',
            successMessage: 'Принято ускоренно'
          },
        },
        loadingAction: false,
        disabledIsPractice: false,
      }
    },
    computed: {
      ...mapState('kkk', ['teachingPositions'])
    },
    watch: {
      'contract.contract_term'() {
        if ([1, 2, 3].includes(this.contract.contract_term)) {
          this.contract.contract_end_date = `${new Date().getFullYear() + this.contract.contract_term}-08-31`
          return
        }

        let month = new Date().getMonth()
        let countMonth = month + 6 + 1

        let day = new Date().getDate()
        day = (day < 10 ? '0' : '') + day

        let year = new Date().getFullYear()

        if (countMonth <= 12) {
          month = month + 6
        } else {
          month = countMonth - 12
          year += 1
        }
        month = (month < 10 ? '0' : '') + month
        this.contract.contract_end_date = `${year}-${month}-${day}`
      }
    },
    methods: {
      ...mapActions('kkk', ['GET_VACANCY_APPLICATIONS',
        'POST_DH_CONFIRM', 'POST_DH_REJECT', 'POST_DH_CONFIRM_FAST', 'POST_CONTRACT',
        'POST_TEACHER_TYPE',
        'GET_TEACHING_POSITIONS'
      ]),
      ...mapMutations('userinfo', ['SET_USER_ID']),
      convertTimestampToDate,
      goToUser(userId) {
        this.SET_USER_ID(userId)
        this.$router.push('/user')
      },

      openAddContractDialog(userId) {
        const user = this.vacancyApplications.find(i => i.id === userId)
        if (user) {
          this.contract = {
            contract_type: 1,
            work_start_date: new Date().toISOString().slice(0, 10),
            contract_start_date: new Date().toISOString().slice(0, 10),
            contract_end_date: `${new Date().getFullYear() + 1}-08-31`,
            user_id: null,
            contract_term: 1,
            teaching_position_id: null
          }

          this.contractUserName = `${user?.lastname} ${user?.firstname}`
          this.contract.user_id = userId
          this.contract.is_checked = user.is_checked
          this.displayAddContract = true
        }
      },
      closeAddContractDialog() {
        this.displayAddContract = false
      },
      async addContract() {
        console.log('addContract', this.contract)

        const form = {
          ...this.contract,
          work_start_date: convertDateInputToTimestamp(this.contract.work_start_date),
          contract_start_date: convertDateInputToTimestamp(this.contract.contract_start_date),
          contract_end_date: convertDateInputToTimestamp(this.contract.contract_end_date),
        }

        const res = await this.POST_CONTRACT(form)
        if (res) {
          await this.getVacancyApplications()
          this.$message({title: 'Контракт успешно создан'})
        }

        this.closeAddContractDialog()
      },
      openActionApplication(userId, status) {
        this.selectUser(userId, status)
        this.displayActionApplication = true
      },
      closeActionApplication() {
        this.displayActionApplication = false
      },
      async actionApplication() {
        this.loadingAction = true

        const form = this.getForm()

        let res = false

        switch (this.selectedUser.status.id) {
          case this.statuses.confirm.id:
            res = await this.POST_DH_CONFIRM(form)
            break
          case this.statuses.reject.id:
            res = await this.POST_DH_REJECT(form)
            break
          case this.statuses.fast.id:
            res = await this.POST_DH_CONFIRM_FAST(form)
            break
        }

        if (res) {
          await this.getVacancyApplications()
          this.$message({title: this.selectedUser.status.successMessage})
        }


        this.loadingAction = false
        this.closeActionApplication()
      },
      selectUser(userId, status) {
        this.selectedUser.comment = ''
        this.selectedUser.status = status
        this.selectedUser.userSyllabuses = []
        this.selectedUser.user = this.vacancyApplications.find(i => i.id === userId)
      },
      getForm() {
        return {
          comment: this.selectedUser.comment,
          ids: this.selectedUser.userSyllabuses
        }
      },
      async getVacancyApplications() {
        const vacancyApplications = await this.GET_VACANCY_APPLICATIONS()

        vacancyApplications.forEach(v => {
          for (let s of v.applications) {
            let status = 0

            if (+s.confirm_status === 1) {
              status = this.statuses.confirm.id
            } else if (+s.reject_status === 1) {
              status = this.statuses.reject.id
            } else if (+s.without_kkk === 1) {
              status = this.statuses.fast.id
            }
            s.status = status
          }
        })

        this.vacancyApplications = vacancyApplications
      },
      async changeIsPractice(user_id, e) {
        this.disabledIsPractice = true

        const res = await this.POST_TEACHER_TYPE({user_id, is_checked: e.target.checked})
        if (res) {
          this.contract.is_checked = 1
          await this.getVacancyApplications()
        }

        this.disabledIsPractice = false
      }
    },
    async mounted() {
      await this.GET_TEACHING_POSITIONS()
      await this.getVacancyApplications()
      this.loading = false
    },
  }

</script>
